import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignOutAlt,
  faMoneyCheck,
  faMoneyBill,
  faArrowUp,
  faTag,
  faArrowDown,
  faDoorOpen,
  faWrench,
  faHome,
  faHistory,
  faFileInvoice,
  faTint,
  faBell,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MyApartment = () => {
  const userDataString = localStorage.getItem("user");
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false); // To toggle notifications dropdown
  const [notifications, setNotifications] = useState([]);
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const [webUrl, setWebUrl] = useState("");
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userName = userData?.fullName || "User";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");

        // Ensure token exists before making request
        if (!token) {
          console.error("Token is missing.");
          return;
        }

        // Fetch notifications count
        const notificationsResponse = await axios.get(
          "https://backend.inyumba.co.ke/iNyumba/inappnotifications/viewallnotifications",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Update state with notifications count
        setNotificationsCount(notificationsResponse.data.response.length);
        setNotifications(notificationsResponse.data.response);
      } catch (error) {
        console.error("Error fetching notifications:", error);
        // You can also set an error state if you want to display error messages
      }
    };

    fetchData();
  }, []); // Empty dependency array, runs only on component mount

  // Toggle Notifications Dropdown
  const toggleNotifications = () => {
    setIsNotificationsOpen(!isNotificationsOpen);
  };

  const handleBillPaymentsClick = () => {
    // Action to perform when Bill Payments is clicked
    navigate("/user/bills");
  };

  const handleVacatingClick = () => {
    // Action to perform when Vacating is clicked
    navigate("/user/viewvacate");
  };

  const handleWaterbillClick = () => {
    // Action to perform when Waterbill is clicked
    navigate("/user/waterbill");
  };

  const handleReservationClick = () => {
    // Action to perform when Reservation is clicked
    navigate("/user/reservation");
  };

  const handleMaintenanceRequestClick = () => {
    // Action to perform when MaintenanceRequest is clicked
    navigate("/user/maintenancerequest");
  };

  const handlePreviousReservationsClick = () => {
    // Action to perform when PreviousReservations is clicked
    navigate("/user/previousreservation");
  };

  const handleProfileClick = () => {
    navigate("/user/profile");
  };

  const handleLogoutClick = () => {
    navigate("/logout");
  };

  const handleSettlementRecords = () => {
    navigate("/user/settlement");
  };

  const handleWithdrawalsClick = () => {
    // Action to perform when Withdrawals is clicked
    navigate("/user/withdrawals");
  };

  const handleAgencyFeeClick = () => {
    // Action to perform when Agency Fee is clicked
    navigate("/user/agencyviewingfee");
  };

  const handlePromoCodeClick = () => {
    // Action to perform when Promo Code is clicked
    navigate("/user/promocode");
  };
  const handleDepositClick = () => {
    // Action to perform when Promo Code is clicked
    navigate("/user/deposits");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <p className="text-1xl font-semibold mb-3">iNyumba: My Apartment</p>
        <div className="flex items-center justify-between p-4 bg-white-600 text-black">
          {/* Profile Icon */}
          <div className="relative">
            <div className="cursor-pointer" onClick={handleProfileClick}>
              <FontAwesomeIcon icon={faUser} className="text-3xl" />
            </div>
          </div>

          {/* Notifications Icon */}
          <div className="relative">
            <FontAwesomeIcon
              icon={faBell}
              className="text-3xl cursor-pointer"
              onClick={toggleNotifications}
            />
            {notificationsCount > 0 && (
              <span className="absolute top-0 right-0 text-xs bg-red-600 text-white rounded-full w-4 h-4 flex items-center justify-center">
                {notificationsCount}
              </span>
            )}
            {/* Notifications Dropdown */}
            {isNotificationsOpen && (
              <div className="absolute top-10 right-0 bg-white text-black p-4 rounded-md">
                {notifications.map((notification) => (
                  <div key={notification.notificationid}>
                    {notification.notification}
                  </div>
                ))}
              </div>
            )}
          </div>

          {/* Logout Icon */}
          <div className="cursor-pointer" onClick={handleLogoutClick}>
            <FontAwesomeIcon icon={faSignOutAlt} className="text-3xl" />
          </div>
        </div>
        <h1 className="text-1xl font-semibold text-black-800">
          Hey {userName}, Welcome
        </h1>
        <div className="flex flex-nowrap overflow-x-auto">
          {/* Bill Payments Flexbox */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleBillPaymentsClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Bill Payments Icon */}
              <FontAwesomeIcon icon={faMoneyBill} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">
              Bill Payments
            </div>
          </div>
          {/* Agency Fee */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleAgencyFeeClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Agency Fee Icon */}
              <FontAwesomeIcon icon={faHandHoldingUsd} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">
              House Hunting Fee
            </div>
          </div>
        </div>

        <div className="flex flex-nowrap overflow-x-auto">
          {/* Bill Payments Flexbox */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleWithdrawalsClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faArrowDown} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">Withdrawals</div>
          </div>

          {/* AgencyFee */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handlePromoCodeClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faTag} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">PromoCode</div>
          </div>
          {/* AgencyFee */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleDepositClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faArrowUp} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">Deposits</div>
          </div>
        </div>

        <div className="flex flex-nowrap overflow-x-auto">
          {/* Bill Payments Flexbox */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleReservationClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faHome} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">
              My Apartment
            </div>
          </div>

          {/* AgencyFee */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleMaintenanceRequestClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faWrench} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">
              Maintenace Requests
            </div>
          </div>
          {/* AgencyFee */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleVacatingClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faDoorOpen} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">Vacating</div>
          </div>
        </div>

        <div className="flex flex-nowrap overflow-x-auto">
          {/* AgencyFee */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleWaterbillClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faTint} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">WaterBill</div>
          </div>

          {/* Bill Payments Flexbox */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handleSettlementRecords}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faFileInvoice} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">
              Settlement Records
            </div>
          </div>

          {/* AgencyFee */}
          <div
            className="flex-shrink-0 flex flex-col bg-blue-600 text-white shadow-md rounded-md p-5 m-3 cursor-pointer hover:bg-blue-700 transition"
            onClick={handlePreviousReservationsClick}
          >
            <div className="flex items-center justify-center w-full h-32 bg-blue-500 rounded mb-2">
              {/* Icon or Placeholder */}
              <FontAwesomeIcon icon={faHistory} className="text-5xl" />
            </div>
            <div className="text-center text-lg font-semibold">
              Previous Reservations
            </div>
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default MyApartment;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthGuard } from "../App";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";

const BillsPage = ({ userId }) => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [webUrl, setWebUrl] = useState("");
  const [showIframe, setShowIframe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();
  const [showMpesaModal, setShowMpesaModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(""); // To store the phone number entered

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const token = localStorage.getItem("token");
        const userDataString = localStorage.getItem("user");
        const userData = JSON.parse(userDataString);
        const userId = userData.unique;

        // Fetch user balance
        if (token) {
          const balanceResponse = await axios.get(
            `https://backend.inyumba.co.ke/iNyumba/balance/user/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setBalance(
            balanceResponse.data.response.length > 0
              ? balanceResponse.data.response[0].balance
              : 0
          );
        }

        // Fetch bills
        const response = await axios.get(
          `https://backend.inyumba.co.ke/iNyumba/admin/bill/view-bill/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Check if there are bills and update state accordingly
        if (response.data.response.length === 0) {
          setBills([]); // No bills available
          toast.info("No bills available.");
        } else {
          setBills(response.data.response);
        }
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.response
          : "An unexpected error occurred.";
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchBills();
  }, [userId]);

  const handlePayments = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/user/payment/initiate-payment/${userId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (
        response.data.success === true &&
        (response.data.response === "You have already paid." ||
          response.data.response === "Already Paid" ||
          response.data.response === "Sorry you have no active reservations" ||
          response.data.response === "Bill details not found!")
      ) {
        toast.error(response.data.response);
      } else {
        setWebUrl(response.data.response);
        toast.success("Please Complete Payment");
        setShowIframe(true);
      }
    } catch (error) {
      // Handle the error appropriately
      const errorMessage = error.response
        ? error.response.data.response
        : "An unexpected error occurred.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMpesaPayments = async (phoneNumber) => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/user/mpesapayment/rentpaymentinitiatestk/${userId}`,
        { phoneNumber }, // Send the phone number in the request payload
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.response.success === true) {
        const responseMessage = response.data.response;

        // Handle specific response scenarios
        if (
          responseMessage === "You have already paid." ||
          responseMessage === "Already Paid" ||
          responseMessage === "Sorry you have no active reservations" ||
          responseMessage === "Bill details not found!"
        ) {
          toast.info(responseMessage); // Inform the user about the required PIN input
        }
      } else {
        toast.info("Kindly input your pin on mpesa pop up");
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.response ||
        "Kindly enter your pin on stk pop up/contact admin.";
      toast.info(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseIframe = () => {
    setShowIframe(false); // Hide the iframe
    setWebUrl(""); // Clear the payment URL
    navigate("/user/myapartment");
  };

  const handlePayViaWallet = async () => {
    setIsLoading(true);
    const token = localStorage.getItem("token");
    const userDataString = localStorage.getItem("user");
    const userData = JSON.parse(userDataString);
    const userId = userData.unique;

    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/payviawallet/${userId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success("Payment successful via wallet!");
      } else {
        toast.error(response.data.response);
      }
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data.response
        : "An unexpected error occurred.";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseMpesaModal = () => {
    setShowMpesaModal(false); // Close the modal
    setPhoneNumber(""); // Clear phone number input field
  };

  const handleOkMpesaModal = () => {
    if (phoneNumber.trim() === "") {
      toast.error("Please enter a phone number.");
      return;
    }

    // Pass the phone number to handleMpesaPayments
    handleMpesaPayments(phoneNumber);
    setShowMpesaModal(false); // Close the modal after OK is clicked
    setPhoneNumber("");
  };

  return (
    <AuthGuard>
      <div className="container mx-auto w-full h-screen">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-fulls">
          <div className="bg-white shadow-md rounded-md p-6 w-fulls">
            <div className="flex items-center justify-between mb-4 w-fulls">
              <h2 className="text-lg font-semibold">Bill Details</h2>
              <span className="text-1xl font-semibold mb-8">
                Date: {new Date().toLocaleDateString()}
              </span>
            </div>
            {loading ? (
              <div className="bg-white shadow-md rounded-md p-4">
                <p className="text-lg font-semibold mb-4">Payment</p>
                <p className="text-gray-700 mb-2">Loading...</p>
                <div className="flex flex-col gap-4">
                  <button
                    onClick={handlePayments}
                    className={`bg-blue-500 text-white px-4 py-2 rounded ${
                      isLoading
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-blue-600"
                    }`}
                    // disabled={isLoading}
                    disabled={true} // Disable the button so nothing happens when clicked
                  >
                    {isLoading ? "Loading..." : "Pay for bills via iPay"}
                  </button>

                  {/* New M-Pesa payment button for deposit */}
                  <button
                    onClick={() => setShowMpesaModal(true)} // Open the modal
                    className={`bg-green-500 text-white px-4 py-2 rounded ${
                      isLoading
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-orange-600"
                    }`}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : "Pay for bills via M-Pesa"}
                  </button>
                </div>
              </div>
            ) : (
              <>
                {bills.length === 0 ? (
                  <div className="flex flex-col gap-4">
                    <button
                      onClick={handlePayments}
                      className={`bg-blue-500 text-white px-4 py-2 rounded ${
                        isLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-blue-600"
                      }`}
                      // disabled={isLoading}
                      disabled={true} // Disable the button so nothing happens when clicked
                    >
                      {isLoading ? "Loading..." : "Pay for deposit via iPay"}
                    </button>
                    {/* New M-Pesa payment button for deposit */}

                    <button
                      onClick={() => setShowMpesaModal(true)} // Open the modal
                      className={`bg-green-500 text-white px-4 py-2 rounded ${
                        isLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-orange-600"
                      }`}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Pay Deposit via M-Pesa"}
                    </button>
                  </div>
                ) : (
                  <div className="grid grid-cols-2 gap-2">
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold text-gray-600">
                        Water Bill:
                      </span>
                      <span className="text-lg font-bold text-blue-600">
                        Ksh {bills.WaterBill}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold text-gray-600">
                        Rent Bill:
                      </span>
                      <span className="text-lg font-bold text-blue-600">
                        Ksh {bills.RentBill}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold text-gray-600">
                        Trash Bill:
                      </span>
                      <span className="text-lg font-bold text-blue-600">
                        Ksh {bills.Trashbill}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-sm font-semibold text-gray-600">
                        Total Amount:
                      </span>
                      <span className="text-lg font-bold text-blue-600">
                        Ksh {bills.TotalAmount}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <button
                        onClick={handlePayments}
                        className={`bg-blue-500 text-white px-4 py-2 rounded ${
                          isLoading
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-blue-600"
                        }`}
                        disabled={true}
                      >
                        {isLoading ? "Loading..." : "Pay for bills"}
                      </button>
                      <button
                        onClick={() => setShowMpesaModal(true)} // Open the modal
                        className={`bg-green-500 text-white px-4 py-2 rounded ${
                          isLoading
                            ? "opacity-50 cursor-not-allowed"
                            : "hover:bg-orange-600"
                        }`}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading..." : "Pay for bills via M-Pesa"}
                      </button>
                    </div>
                  </div>
                )}

                {/* Show the Pay via Wallet button if balance is sufficient */}
                {balance >= bills.TotalAmount && (
                  <div className="mt-4">
                    <button
                      onClick={handlePayViaWallet}
                      className={`bg-green-500 text-white px-4 py-2 rounded ${
                        isLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:bg-green-600"
                      }`}
                      disabled={isLoading}
                    >
                      {isLoading ? "Loading..." : "Pay via Wallet"}
                    </button>
                  </div>
                )}
              </>
            )}

            <p className="text-lg mb-6">
              Incase you don't receive a prompt or you get an issue with the
              prompt, you can use the manual payment for the same purpose{" "}
            </p>

            {/* Add Image Below Top Section */}
            <div className="mb-4">
              <img
                src="https://www.dropbox.com/scl/fi/59j5xgbfu54uuez6ihqmb/bb4c47329bbbe2fb.jpg?rlkey=w9hhrjvbc1qxkzjv4wl6yzc3o&raw=1"
                alt="Top Section Image"
                className="w-full h-auto rounded-md shadow"
              />
            </div>

            {/* Conditionally render the iframe with close button */}
            {showIframe && webUrl && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
                <button
                  onClick={handleCloseIframe}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mb-4"
                >
                  Close
                </button>
                <iframe
                  src={webUrl}
                  className="w-screen h-screen border-none mx-auto"
                  title="Payment Iframe"
                ></iframe>
              </div>
            )}

            {showMpesaModal && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex flex-col items-center justify-center">
                <div className="bg-white p-6 rounded-lg shadow-lg w-96">
                  <div className="flex justify-between items-center">
                    <h3 className="text-lg font-semibold">
                      Enter Phone Number for M-Pesa
                    </h3>
                    <button
                      onClick={handleCloseMpesaModal}
                      className="text-red-500 hover:text-red-700"
                    >
                      <FaTimes />
                    </button>
                  </div>

                  <div className="mt-4">
                    <input
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="w-full p-2 border rounded-md"
                      placeholder="Enter phone number"
                    />
                  </div>

                  <div className="flex gap-4 mt-4">
                    <button
                      onClick={handleCloseMpesaModal}
                      className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleOkMpesaModal}
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthGuard>
  );
};

export default BillsPage;

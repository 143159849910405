import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthGuard } from "../App";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddComment = () => {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0); // Initially no stars selected
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const userDataString = localStorage.getItem("user");
  const userData = JSON.parse(userDataString);
  const userId = userData.unique;
  const propertyId = localStorage.getItem("specificPropertyId");

  const handleStarClick = (value) => {
    setRating(value === rating ? 0 : value); // Toggle the rating
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `https://backend.inyumba.co.ke/iNyumba/admin/comments/add/${propertyId}`,
        {
          comments: comment,
          ratings: rating,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success(response.data.response);
      navigate("/user/myapartment");
    } catch (error) {
      console.error("Error adding comment:", error);
      toast.error(error.response.data.response);
      // Handle error
    }
  };

  return (
    <AuthGuard>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-4">Add Comment</h1>
        <div className="mb-4">
          <label
            htmlFor="comment"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Comment
          </label>
          <textarea
            id="comment"
            className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            rows="5"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Rating
          </label>
          <div className="flex">
            {[1, 2, 3, 4, 5].map((value) => (
              <FontAwesomeIcon
                key={value}
                icon={value <= rating ? solidStar : regularStar}
                className="cursor-pointer text-yellow-400 text-2xl mr-2"
                onClick={() => handleStarClick(value)}
              />
            ))}
          </div>
        </div>
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={handleSubmit}
        >
          Add Comment
        </button>
      </div>
    </AuthGuard>
  );
};

export default AddComment;

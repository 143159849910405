import React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TokenGuard } from "../App";

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Retrieve the token from local storage
      const token = localStorage.getItem("token");

      // Send a POST request to the logout endpoint with the token in the Authorization header
      await axios.post(
        "https://backend.inyumba.co.ke/iNyumba/auth/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Display toast message of success
      toast.success("Logout successful!");
      // Redirect to the login page
      navigate("/");
      // Clear the token and other data from local storage
      localStorage.clear();
    } catch (error) {
      console.error(error); // Display error message
    }
  };

  const handleNo = () => {
    // Navigate back to the home page
    navigate("/user/myapartment");
  };

  return (
    <TokenGuard>
      <div className="flex flex-col items-center justify-center h-screen">
        <h2 className="text-xl font-semibold mb-4">
          Are you sure you want to log out?
        </h2>
        <div className="flex space-x-4">
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
          >
            Yes
          </button>
          <button
            onClick={handleNo}
            className="bg-gray-400 hover:bg-gray-500 text-white px-4 py-2 rounded-md"
          >
            No
          </button>
        </div>
      </div>
    </TokenGuard>
  );
};

export default Logout;
